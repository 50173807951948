import React from 'react';
import logo from "./logo.png";
import "./logo.scss"
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <Link to="/"  className='logo' >
            <img className='logo__img' src={logo} alt="Улитка" />
            <div className='logo__text' >
                <p className='logo__title'>Helix</p>
                <p className='logo__slogan'>Float studio</p>
            </div>
        </Link>
    );
};

export default Logo;