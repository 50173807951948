import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./slider.scss"

const Slider = (props) => {

    return (
        <>
            <div className='slider'>
            <Swiper pagination={{
                type: 'progressbar',
            }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper" >
                {props.images.map(({id, img}) => {
                    return <SwiperSlide key={id} ><img src={img} alt=''/></SwiperSlide>
                })}
            </Swiper>
            </div>
        </>
    );
}

export default Slider;


