import React from 'react';
import Visit from "../visit";
import "./top-bar.scss"


const TopBar = () => {

    return (
        <div className='top-bar'>
            <div className='top-bar__wrap contain'>
                <Visit/>
            </div>
        </div>
    );
};

export default TopBar;