import {Routes, Route} from "react-router-dom";
import './app.scss';
import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Sessions from "./pages/sessions";
import Reviews from "./pages/reviews";
import Contacts from "./pages/contacts";
import News from "./pages/news";
import NotFound from "./pages/not-found";
import Blog from "./pages/blog";

function App() {
    return (
        <div className="app">
            <Header/>
            <Routes>
                <Route index element={<Home/>}/>
               <Route path='home' element={<Home/>}/>
                <Route path='sessions' element={<Sessions/>}/>
                <Route path='reviews' element={<Reviews/>}/>
                <Route path='contacts' element={<Contacts/>}/>
                <Route path='news' element={<News/>}/>
                <Route path='blog' element={<Blog/>}/>
                <Route path='*'  element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
