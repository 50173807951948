import React from 'react';
import './visit.scss'
//img
import phone from "./img/phone.svg"
import location from "./img/location.svg"
import clock from "./img/clock.svg"
import mail from "./img/mail.svg"
import instagram from "./img/instagram.svg"



const Visit = () => {
    return (
        <div className='visit'>
            <div className='visit__tel'><a className='visit__item' href="tel:+375255021523"><img className="visit__img" src={phone} alt="phone"/>+375 25 502 15 23 c 10:00 до 22:00</a></div>
            <div className='visit__address'><a className='visit__item' href="https://maps.google.com?saddr=Current+Location&daddr=53.90477644461072,27.619425078542726"><img className="visit__img" src={location} alt="location"/>Минск, Бумажкова 37А</a></div>
            <div className='visit__item visit__time'><img className="visit__img" src={clock} alt="clock"/><span>по предварительной записи</span></div>
            <div className='visit__item '><a className='visit__item' href="mailto:helix@float.by"><img className="visit__img" src={mail} alt="mail"/>helix@float.by</a></div>
            <div className='visit__item '><a className='visit__item' href="https://www.instagram.com/helix_float_studio"><img className="visit__img" src={instagram} alt="instagram"/>helix_float_studio</a></div>

        </div>
    );
};

export default Visit;