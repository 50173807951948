import React from 'react';

const NotFound = () => {
    return (
        <main style={{ height:"80vh",padding: '100px 0', textAlign: 'center', fontSize: '72px', color:"wheat"}}>
            <p>Такой страницы, к сожелению, нет</p>
        </main>
    );
};

export default NotFound;