import fringe from "./cameo/fringe.png"
import house from "./cameo/house.png"
import sorvigolova from "./cameo/sorvigolova.png"
import shortbus from "./cameo/shortbus.png"
import simpson from "./cameo/simpson.png"
import stranger from "./cameo/stranger.png"
import elementary from "./cameo/elementary.png"
import theori from "./cameo/theori.png"
import pretender from "./cameo/pretender.png"
import gravity from "./cameo/gravity.png"

const CAMEO = [
    {
        id: 1,
        img: fringe,
        title: "Cериал \"За гранью\" (Fringe)",
        text: "Флоатинг помогает главной героине под руководством профессора Уолтера Бишопа активировать ментальную силу и открыть врата в альтернативную реальность."
    },
    {
        id: 2,
        img: sorvigolova,
        title: "Фильм \"Сорвиголова\" (2003)",
        text: "В фильме главный герой использовал камеру сенсорной депривации для изоляции от внешнего мира, это единственный для него способ уснуть."
    },
    {
        id: 3,
        img: house,
        title: "Cериал \"Доктор Хаус\"",
        text: "Флоатинг ванну испытывает герой сериала Хью Лори в 85-й серии, пытающийся во время релаксации восстановить в памяти события, произошедшие во время аварии."
    },
    {
        id: 4,
        img: shortbus,
        title: "Фильм \"Клуб «Shortbus»\" (18+)",
        text: "В провокационном фильме «Клуб Shortbus» героини проводят время в камере сенсорной депривации."
    },
    {
        id: 5,
        img: simpson,
        title: "Мультсериал \"Симпсоны\"",
        text: "Лизе и Гомеру специалист по нетрадиционной медицине «прописывает» депривационные камеры."
    },
    {
        id: 6,
        img: stranger,
        title: "Сериал \"Очень странные дела\"",
        text: "Сенсорная дипривация использовалась практически по тому же назначению, что и в сериале \"За гранью\"."
    },
    {
        id: 7,
        img: elementary,
        title: "В сериале \"Элементарно\"",
        text: "Главный герой Шерлок Холмс использует камеру для снятия симптомов от черепно-мозговой травмы."
    },
    {
        id: 8,
        img: theori,
        title: "В ситкоме \"Теория Большого взрыва\"",
        text: "Шелдон и Эми пробуют сенсорную депривацию"
    },
    {
        id: 9,
        img: pretender,
        title: "В сериале \"Притворщик\"",
        text: "Главный герой использует камеру для ощущения защиты и размышлений о матери"
    },
    {
        id: 10,
        img: gravity,
        title: "В сериале \"Притяжение вопреки\"",
        text: "Подготовка экипажа космического коробля включала в себя сеансы сенсорной дипривации"
    },
]

export default CAMEO;