import React, {useState} from 'react';
import "./contact.scss"
import EasyWeek from "../easy-week";

const Contact = () => {

    const [isRegistrationOpen, toggleRegistration] = useState(false)

    function toggleMenuMode() {
        toggleRegistration(!isRegistrationOpen)
    }

    const clickHandlerRegistration = () => {
        if (!isRegistrationOpen) {
            document.body.style.overflow = 'hidden';
        }
        toggleMenuMode();
    };
    const clickCloseRegistration = () => {
        if (isRegistrationOpen) {
            document.body.style.overflow = '';
            toggleMenuMode();
        }
    }
    return (

        <div onClick={clickCloseRegistration}>
            <div className='contact contain'>
                <iframe title=" " className='contact__map' src="https://yandex.by/map-widget/v1/?z=12&ol=biz&oid=83811035199" frameBorder="0"/>
                <div className='contact__block'>
                    <ul className='contact__list'>
                        <li className='contact__item'>Минск. ул.Бумажкова д.37а</li>
                        <li className='contact__item'>телефон: <a href="tel:+375255021523">+375 25 502 15 23</a></li>
                        <li className='contact__item'>инстаграмм: <a href="https://www.instagram.com/helix_float_studio/">helix_float_studio</a></li>
                        <li className='contact__item'>почта: <a href="mailto:helix@float.by">helix@float.by</a></li>
                        <li>
                            <button onClick={clickHandlerRegistration} className="contact__button">Записаться на сеанс</button>
                        </li>
                    </ul>
                </div>
            </div>
            {isRegistrationOpen && <EasyWeek/>}

        </div>
    );
};

export default Contact;