import React from 'react';
import './about.scss';
import Title from "../title";
import float from './float.png';
import banner from './float-2.png'


const About = () => {
    return (
        <div className='about '>
            <Title title={'Флоатинг'}/>
            <div className='about__block contain'>
                <div className='about__item'>
                    <img className='about__img about__img--norevers' src={float} alt=""/>
                    <dl className='about__text'>
                        <dt className='about__title'>Процедура флоатинга</dt>
                        <dd className='about__description'>
                            <div className="about__definition">ФЛОАТИНГ - метод релаксации, снимающий постоянные внешние воздействия c органов чувств человека путем погружения в СОСТОЯНИЕ НЕВЕСОМОСТИ.</div>
                            <div className="about__phrase">Процедура воздействует как на физическом, так и на психическом уровнях.</div>
                            <ul className="about__list"><p>Флоатинг терапия </p>
                                <li>стабилизирует кровяное давление,</li>
                                <li>нормализует пульс и сердечный ритм,</li>
                                <li>улучшает лимфодренаж,</li>
                                <li>способствует снижению избыточного веса,</li>
                                <li>питает кожу, восстанавливает волосы</li>
                                <li>снимает болевой синдром различного происхождения (мигрень, боли в спине, остеохондроз, артриты).</li>
                            </ul>
                            <ul className="about__list"><p>Процедура помогает </p>
                                <li>стимулировать работу мозга,</li>
                                <li>снимать стресс,</li>
                                <li>помогает в борьбе с депрессиями и тревогами,</li>
                                <li>увеличивает производство эндорфинов - гормонов счастья,</li>
                                <li>дарит чувство гармонии,защищенности и покоя,</li>
                                <li>которое остается надолго после сеанса.</li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div className='about__item'>
                    <img className='about__img about__img--revers' src={banner} alt=""/>
                    <dl className='about__text'>
                        <dt className='about__title'>Камера сенсорной депривации</dt>
                        <dd className='about__description'>
                            <ul className="about__list"><p>В настоящее время существуют различные</p>
                                <li>флоат камеры,</li>
                                <li>флоат комнаты,</li>
                                <li>флоат бассейны</li>
                                <li>и флоат ложа.</li>
                            </ul>
                            <ul className="about__list"><p>Все они основываются на погружении в воду, но есть принципиальное отличие:</p>
                                <li>они могут быть как ВЛАЖНЫЕ,</li>
                                <li>так и СУХИЕ.</li>
                            </ul>
                            <div className="about__phrase"> Преимуществом ВЛАЖНЫХ флоат камер является ПОЛНАЯ изоляция от внешнего мира, отсутствие звуков, запахов, света. Это возможность побыть наедине с собой, не отвлекаясь на суету окружающего мира.</div>
                            <ul className="about__list"><p> В шашей студии установлена флоат камера для парного сеанса ДЖИВА ЛЮКС LL. Аналогичные камеры этой фирмы установлены в таких городах как:</p>
                                <li>Барнаул,
                                    Белгород,
                                </li>
                                <li>Вильнюс,
                                    Воронеж,
                                </li>
                                <li>Кемерово,
                                    Краснодар, Лобня,
                                </li>
                                <li>
                                    Москва, Новосибирск,
                                    Ростов-на-Дону,
                                </li>
                                <li>Сочи,
                                    Уфа,
                                    Челябинск и др.
                                </li>


                                <p>А теперь и в МИНСКЕ!</p>
                            </ul>

                        </dd>
                    </dl>
                    <img className='about__img ' src={banner} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default About;