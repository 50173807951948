import React from 'react';
import './footer.scss'

const Footer = () => {
    return (
        <div className='footer contain'>
            <div>2023 Helix Float Studio</div>
            <div>сделал  Medzviadziou</div>
        </div>
    );
};

export default Footer;