import React from 'react';
import "./sessions.scss"
import Title from "../../components/title";
import Cards from "../../components/cards";

const Sessions = () => {
    return (
        <div className='sessions'>
            <Title title={'Цены:'}/>
            <Cards/>
        </div>
    );
};

export default Sessions;