import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {Keyboard, Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./slider-cameo.scss"


import Title from "../title";
import Cameo from "../cameo";
import CAMEO from "../../data/cameoList";




const SliderCameo = () => {
    return (
        <div className='slider-cameo'>
            <div className='contain'>
            <Title title={'Флоатинг в кино'}  black={true}/>
            <div className='slider-cameo__slider'>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                keyboard={{
                    enabled: true,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Keyboard, Pagination, Navigation]}
                className="mySwiper"
            >
                {CAMEO.map(({id, img, title,text}) => {
                    return <SwiperSlide key={id} ><Cameo img={img} title={title} text={text} /></SwiperSlide>
                })}

            </Swiper>
            </div>
            </div>
        </div>
    );
}

export default SliderCameo;


