import React from 'react';
import "./banner.scss"


const Banner = () => {

    let text = ['Спа процедура, которая позволяет отдохнуть телу и разуму',
        'Флоатинг лучшая инвестиция в себя!',
        'Флоатинг имеет накопительный эффект!',
        'Флоатинг – уникальная процедура в СОЛЯНОМ ТЕРМАЛЬНОМ БАССЕЙНЕ, дарующая потрясающий релакс и оздоровление всего организма!',
        'Приглашает всех ЗА СТРОЙНЫМ ТЕЛОМ, ШЕЛКОВИСТОЙ КОЖЕЙ И КРАСИВЫМИ ВОЛОСАМИ!',
        'Флоатинг – это то, что стоит испытать!',
        'Флоатинг – самый эффективный и естественный способ расслабления и освобождения от физического, умственного, душевного напряжения и усталости'];
    let textSubTitle = text[Math.floor(Math.random() * text.length)]


    return (
        <div className='banner'>
            <h1 className='banner__text banner__text--title'>ФЛОАТИНГ В МИНСКЕ</h1>
            <p className='banner__text  contain'>{textSubTitle} </p>
         </div>
    );
};

export default Banner;