import React from 'react';
import Banner from "../../components/banner";
import Services from "../../components/services";
import About from "../../components/about";
import Price from "../../components/price";
import Contacts from "../../components/contact";
import SliderCameo from "../../components/slider-cameo";
import Principles from "../../components/principles";

const Home = () => {
    return (
        <main className='home-page'>
            <Banner/>
            <About/>
            <Principles/>
            <Services/>
            <SliderCameo/>
            <Price/>
            <Contacts/>
        </main>
    );
};

export default Home;