import React from 'react';
import './title.scss'
import classNames from "classnames";



const Title = (props) => {
    return (
        <div  className='title contain'>
            <h2 className={classNames('title__text', {'title__text--black': props.black})}>{props.title}</h2>
        </div>
    );
};

export default Title;