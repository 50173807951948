import React from 'react';
import './principles.scss'
import Title from "../title";
import isolation from './isolation.png'
import meditation from './medit.png'
import epsom from './epsom.jpg'

const Principles = () => {
    return (
        <div className='principles'>
            <Title title={"Сочетание трех принципов"}  black={true}/>
            <div className='principles__wrap contain'>
                <p className='principles__slogan'>Флоатинг — это сочетание трех основных принципов. Вместе они обеспечат вам полную релаксацию!</p>
            </div>
            <div  className='principles__box'>
                <div  className='principles__item'>
                    <div  className='principles__img'><img  className='principles__ico' src={isolation} alt=""/></div>
                    <h2  className='principles__title'>СЕНСОРНАЯ ИЗОЛЯЦИЯ</h2>
                    <p  className='principles__text'>Мы постоянно адаптируемся к раздражителям, передаваемым нашими чувствами. То, что мы видим, слышим, чувствуем, постоянно интерпретируется нашим мозгом. <br/>Когда мы оказываемся изолированными от этих внешних раздражителей, наш мозг может перейти на другую частоту мозговых волн (тета), уникальную для МЕДИТАТИВНОГО состояния. Это состояние идеально подходит для подзарядки своих внутренних батарей, а также для обучения, для исследования своего воображения и творчества.</p>
                </div>
                <div  className='principles__item'>
                    <div  className='principles__img'><img className='principles__ico' src={meditation} alt=""/></div>
                    <h2 className='principles__title'>ЭФФЕКТ НЕВЕСОМОСТИ</h2>
                    <p className='principles__text'>Не малая часть нашей мозговой активности направлена на балансирование против силы гравитации. Плавая в водном растворе, насыщенном солью магния, ощущение гравитации снижается, освобождая наши умственные ресурсы для других занятий. Мышечные и суставные боли облегчаются этим ощущением невесомости.</p>
                </div>
                <div  className='principles__item'>
                    <div  className='principles__img'><img className='principles__ico' src={epsom} alt=""/></div>
                    <h2 className='principles__title'>СОЛЬ ЭПСОМА</h2>
                    <p className='principles__text'>Благодаря большому количеству английской соли можно держаться на воде. Эта минеральная соль уже несколько столетий используется для облегчения боли в мышцах и суставах, а также для облегчения проблем с кожей. Это комбинация двух минералов, необходимых для организма: серы и магния. Таким образом, ванна с английской солью помогает уменьшить дефицит этих минералов.</p>
                </div>
            </div>

        </div>
    );
};

export default Principles;