import React from 'react';
import './cameo.scss'

const Cameo = (props) => {
    return (
        <div className='cameo'>
            <div className='cameo__screenshot' ><img className='cameo__img' src={props.img} alt=""/></div>
            <div  className='cameo__caption'>
                <h3 className='cameo__title'>{props.title}</h3>
                <p className='cameo__text'>{props.text}</p>
            </div>
        </div>
    );
};

export default Cameo;