import React, {useState} from 'react';
import {Link} from "react-router-dom";
import MENU from "../../data/menuList";
import './nav.scss'
import classNames from "classnames";


const Nav = () => {

    const [isMenuOpen, toggleMenu] = useState(false)

    function toggleMenuMode() {
        toggleMenu(!isMenuOpen)
    }
    const clickHandler = () => {
        if (!isMenuOpen) {
            document.body.style.overflow = 'hidden';
        }
        toggleMenuMode();
    };
    const clickClose = () => {
        if (isMenuOpen) {
            document.body.style.overflow = '';
            toggleMenuMode();
        }
    }

    return (
        <nav className='nav' onClick={clickClose}>
            <ul className={classNames('nav__list', {'nav__list--open': isMenuOpen})}>
                {MENU.map(({id, name, path}) => {
                    return <li key={id} className='nav__item'><Link to={`/${path}`}>{name}</Link></li>
                })}

            </ul>
            <div className='nav__ico nav__ico--display'>
            <button onClick={clickHandler} className='nav__menu-burger'>
                <span className={classNames('nav__burger', {'nav__burger--1': isMenuOpen})}> </span>
                <span className={classNames('nav__burger', {'nav__burger--2': isMenuOpen})}> </span>
                <span className={classNames('nav__burger', {'nav__burger--3': isMenuOpen})}> </span>
            </button>
        </div>
        </nav>
    );
};

export default Nav;