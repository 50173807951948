import React from 'react';
import "./contacts.scss"
import Contact from "../../components/contact";
import Title from "../../components/title";

const Contacts = () => {
    return (
        <div className="contacts">
            <Title title='Контакты'/>
            <div className='contacts__block contain'>
                <div className='contacts__item'>
                    <p className='contacts__text contacts__number'>Индивидуальный предприниматель </p>
                    <p className='contacts__text contacts__number'>Медведев Игорь Александрович</p>
                </div>
                <div className='contacts__item'>
                    <p className='contacts__text contacts__number'>УНП </p>
                    <span className='contacts__text contacts__number'>391981988</span>
                </div>
                <div className='contacts__item'>
                    <p className='contacts__text contacts__number'>Телефон</p>
                    <span className='contacts__text contacts__number'><a href="tel:+375298521563">+375 29 8521563</a></span>
                </div>
                <div className='contacts__item'>
                    <p className='contacts__text contacts__number'>Телефон для записи</p>
                    <span className='contacts__text contacts__number'><a href="tel:+375255021523">+375 25 5021523</a></span>
                </div>
                <div className='contacts__item'>
                    <p className='contacts__text contacts__number'>E-mail</p>
                    <span className='contacts__text contacts__number'><a href="mailto:medzviadziou@gmail.com">medzviadziou@gmail.com</a></span>
                </div>
                <div className='contacts__item'>
                    <p className='contacts__text contacts__number'>Адрес</p>
                    <span className='contacts__text contacts__number'>г.Минск ул.Бумажкова 37А пом.149</span>
                </div>
            </div>
            <Contact/>
        </div>
    );
};

export default Contacts;