import React, {useState} from 'react';
import "./header.scss"
import Logo from "../logo";
import Nav from "../nav";
import EasyWeek from "../easy-week";
import TopBar from "../top-bar";

const Header = () => {
    const [isRegistrationOpen, toggleRegistration] = useState(false)

    function toggleMenuMode() {
        toggleRegistration(!isRegistrationOpen)
    }

    const clickHandlerRegistration = () => {
        if (!isRegistrationOpen) {
            document.body.style.overflow = 'hidden';
        }
        toggleMenuMode();
    };
    const clickCloseRegistration = () => {
        if (isRegistrationOpen) {
            document.body.style.overflow = '';
            toggleMenuMode();
        }
    }
    return (
        <div onClick={clickCloseRegistration} className='header'>
            <TopBar/>
            <div className='header__wrap contain'>
                <Logo/>
                <Nav/>
                <button onClick={clickHandlerRegistration} className="header__button">Записаться на сеанс</button>
            </div>

            {isRegistrationOpen && <EasyWeek/>}
        </div>
    );
};

export default Header;