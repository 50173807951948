import nev1 from "./price-img/nevesomost/1.png"
import nev2 from "./price-img/nevesomost/2.png"
import nev3 from "./price-img/nevesomost/3.png"
import nev4 from "./price-img/nevesomost/4.png"
import nev5 from "./price-img/nevesomost/5.png"
import parn1 from "./price-img/parn/1.png"
import parn2 from "./price-img/parn/2.png"
import parn3 from "./price-img/parn/3.png"
import parn4 from "./price-img/parn/4.png"
import parn5 from "./price-img/parn/5.png"
import light1 from "./price-img/light/1.png"
import light2 from "./price-img/light/2.png"
import ultra1 from "./price-img/ultra/1.png"
import ultra2 from "./price-img/ultra/2.png"
import mega1 from "./price-img/mega/1.png"
import mega2 from "./price-img/mega/2.png"
/*import all1 from "./price-img/all/1.png"
import all2 from "./price-img/all/2.png"
import all3 from "./price-img/all/3.png"
import massage_mini1 from "./price-img/massage/mini/1.png"
import massage_standart1 from "./price-img/massage/standart/1.png"
import massage_standart2 from "./price-img/massage/standart/2.png"
import massage_standart3 from "./price-img/massage/standart/3.png"
import massage_standart4 from "./price-img/massage/standart/4.png"
import massage_standart5 from "./price-img/massage/standart/5.png"
import massage_standart6 from "./price-img/massage/standart/6.png"
import massage_mega1 from "./price-img/massage/mega/1.png"*/

const PRICE = [
    { id:1, name:"SPA-процедура \"НЕВЕСОМОСТЬ\"", price:110, description:"Флоатинг 1 час + чаепитие в лаундж зоне",
        images:[
            {id:1,img:nev1},
            {id:2,img:nev2},
            {id:3,img:nev3},
            {id:4,img:nev4},
            {id:5,img:nev5},
        ]},
    { id:2, name:"SPA-процедура \"СВИДАНИЕ\"", price:130, description:"Парный флоатинг 1 час + чаепитие в лаундж зоне",
        images:[
            {id:1,img:parn1},
            {id:2,img:parn2},
            {id:3,img:parn3},
            {id:4,img:parn4},
            {id:5,img:parn5},
        ]},
    { id:3, name:"SPA-процедура \"ЛАЙТ\"", price:60, description:"Флоатинг 30 минут + чаепитие в лаундж зоне",
        images:[
            {id:1,img:light1},
            {id:2,img:light2},
        ]},
    { id:4, name:"SPA-процедура \"УЛЬТРА\"", price:180, description:"Флоатинг 2 час + чаепитие в лаундж зоне",
        images:[
            {id:1,img:ultra1},
            {id:2,img:ultra2},
        ]},
    { id:5, name:"SPA-процедура \"МЕГА\"", price:250, description:"Флоатинг 3 час + чаепитие в лаундж зоне",
        images:[
            {id:1,img:mega1},
            {id:2,img:mega2},
        ]},
/*    { id:6, name:"SPA-процедура \"ВСЕ ВКЛЮЧЕНО\"", price:140, description:"Флоатинг 1 час + класический массаж + чаепитие в лаундж зоне",
        images:[
            {id:1,img:all1},
            {id:2,img:all2},
            {id:3,img:all3},
        ]},*/
/*    { id:7, name:"Класический массаж \"МИНИ\"", price:27, description:"Класический релакс массаж продолжительностью 30 минут",
        images:[
            {id:1,img:massage_mini1},
        ]},*/
  /*  { id:8, name:"Класический массаж \"СТАНДАРТ\"", price:40, description:"Класический релакс массаж продолжительностью 1 час",
        images:[
            {id:1,img:massage_standart1},
            {id:2,img:massage_standart2},
            {id:3,img:massage_standart3},
            {id:4,img:massage_standart4},
            {id:5,img:massage_standart5},
            {id:6,img:massage_standart6},
        ]},*/
/*    { id:9, name:"Класический массаж \"МЕГА\"", price:65, description:"Класический релакс массаж продолжительностью 2 час",
        images:[
            {id:1,img:massage_mega1},
        ]},*/
    { id:10, name:"SPA-процедура \"ПАРНЫЙ МИНИ\"", price:100, note:"только по записи", description:"Парный флоатинг 30 минут + чаепитие в лаундж зоне",
        images:[
            {id:1,img:parn4},
            {id:2,img:parn5},
            {id:3,img:parn3},
            {id:4,img:parn1},
            {id:5,img:parn2},
        ]},
]

export  default PRICE;
