import React from 'react';
import './cards.scss'
import PRICE from "../../data/priceList";
import Slider from "../slider";



const Cards = () => {
    return (
        <div className='cards contain'>
            <div className='cards__grid'>
                {PRICE.map(({id, name,price, description,discount,note, images}) => {
                    return <div key={id} className='cards__item'>
                        <div>
                            {discount && <span className='cards__sale'>цена идет со скидкой {discount} %</span>}
                            {note && <span className='cards__note'> {note}</span>}
                            <Slider images={images}/>
                            <h3 className='cards__title'>{name}</h3>
                            <p  className='cards__description'>{description}</p>
                            <div className='cards__info'>
                                <span className='cards__price'>{price} руб. {discount && <span className='cards__price cards__price--sale'>  {Math.round(price + (parseInt(discount ?? 0) * (price / 100)))} руб.</span>}</span>
                            </div>
                        </div>
                    </div>
                })}

            </div>
        </div>


    );


};

export default Cards;